.projects {
  display: grid;
  column-gap: 12px;
  row-gap: 24px;

  margin: 16px;
  margin-top: 440px;

  @media (--forPhoneOnly) {
    grid-template-columns: repeat(2, 1fr);
    margin: 8px;
    margin-top: 370px;
  }

  @media (--forTabletPortraitUp) {
    grid-template-columns: repeat(4, 1fr);
  }

  /* @media (--forTabletLandscapeUp) {
    grid-template-columns: repeat(5, 1fr);
  } */

  @media (--forDesktopUp) {
    grid-template-columns: repeat(8, 1fr);
  }

  /* @media (--forBigDesktopUp) {
    grid-template-columns: repeat(8, 1fr);
  } */
}

.projects__item {
  --animation-duration: 0.6s;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: -0.05px;

  cursor: pointer;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (--forPhoneOnly) {
    font-size: 7px;
    line-height: 8px;
  }

  & > p {
    margin: 0;
  }

  & a,
  & a:hover,
  & a:visited,
  & a:active {
    color: #7c7b7c;
    text-decoration-color: #cccccc;
  }

  & > p:first-child {
    margin-bottom: 10px;
  }
}
