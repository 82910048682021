.spots__clips {
  position: absolute;
}

.spots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.spots__item_first {
  clip-path: url(#spot1);

  @media (--forPhoneOnly) {
    top: 360px;
    left: 22px;
  }

  @media (--forTabletPortraitUp) {
    top: 136px;
    left: 666px;
  }
}

.spots__item_second {
  @media (--forPhoneOnly) {
    top: 470px;
    left: 200px;
  }
  @media (--forTabletPortraitUp) {
    top: 352px;
    left: 924px;
  }
  clip-path: url(#spot2);
}

.spots__item_third {
  @media (--forPhoneOnly) {
    top: 280px;
    left: 132px;
  }
  @media (--forTabletPortraitUp) {
    top: 30px;
    left: 953px;
  }
  clip-path: url(#spot3);
}

.spots__item {
  height: 400px;
  position: absolute;

  -webkit-user-select: none;
  -webkit-touch-callout: none;

  @media (--forPhoneOnly) {
    transform: scale(0.6);
    transform-origin: top left;
  }
}
