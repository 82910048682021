@import './components/created/created.css';
@import './components/about/about.css';
@import './components/spots/spots.css';
@import './components/projects/projects.css';

body {
  margin: 0;
  overflow-x: hidden;
}

* {
  font-family: Inter, Helvetica, Arial, sans-serif;
  outline: none;
}
