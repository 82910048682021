.created {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  @media (--forPhoneOnly) {
    margin: 16px 8px;
    font-size: 10px;
    line-height: 14px;
  }
  @media (--forTabletPortraitUp) {
    position: absolute;
    top: -8px;
    right: 16px;
    font-size: 10px;
    line-height: 12px;

    transform: rotate(-90deg);
    transform-origin: right bottom;
  }
}

.created__link {
  color: black;
  text-align: right;
  @media (--forPhoneOnly) {
    text-align: left;
  }
  text-decoration: none;
}

.created__link:hover {
  text-decoration: underline;
}
