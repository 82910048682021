.contacts {
  position: relative;
  top: 0px;
  left: 3px;
  display: inline-block;
  width: 76px;
  height: 25px;

  @media (--forPhoneOnly) {
    width: 105px;
    margin-top: 4px;
  }
}

.contacts__container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 300px;
  overflow-y: hidden;
  border-radius: 13px 13px 0 0;

  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.contacts__button {
  position: relative;
  z-index: 0;

  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 0;
  font-size: 11px;
  font-weight: 300;
  line-height: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  background: #000000;
  border: none;
  border-radius: 13px;

  @media (--forPhoneOnly) {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 15px;
  }
}

.contacts__details {
  position: absolute;
  top: 0px;
  width: calc(100% - 26px);
  padding-top: 7px;
  padding-right: 13px;
  padding-bottom: 7px;
  padding-left: 13px;
  background: #000000;
  border-radius: 13px;

  transform: translateY(-100%);
  & > p {
    display: grid;
    gap: 2px;
    margin: 0;
  }
  & a {
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
    color: white;
    text-decoration: none;
  }

  @media (--forPhoneOnly) {
    border-radius: 15px;
  }
}
